.microsoft-oauth-button {
    display: flex;
    flex-grow: 1;
    position: relative;
    width: 100%;
    height: 42px;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #dadce0; /* matches the google button*/
    background: var(--white);
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
}

.microsoft-oauth-button > :first-child {
    padding-left: 8px;
}

.microsoft-oauth-button span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-placeholder {
    height: 38px;
    box-sizing: border-box;
    padding: 0 4px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey-lt);
}
