.button-card {
    composes: card--shadowed from './card.css';
    composes: border from '../border/border.css';
    position: relative;
    padding: 0;
}

.button-card--dark-bg {
    background: var(--off-white);
}

.button-card::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.button-card:focus-visible:not(.button-card--noClick) {
    outline: 2px solid var(--focus-blue);
    outline-offset: 2px;
}

.button-card:not(.button-card--noClick):hover::after,
.button-card:not(.button-card--noClick):focus::after {
    opacity: 1;
}

.button-card--noClick {
    cursor: default;
}

.button-card--noClick:hover {
    border: 1px solid var(--off-white-dk) !important;
}

.button-card--noClick:focus,
.button-card--noClick:active {
    border: 1px solid var(--off-white-dk) !important;
}

.button-card--selected {
    border-color: var(--blue);
}

.button-card--fitHeight {
    height: auto;
}

.button-card--fullWidth {
    width: 100%;
}

.button-card--needsAttention {
    border-color: var(--orange-attention);
}

.button-card--disabled {
    box-shadow: none;
    border-color: var(--off-white-dk);
    cursor: default;
}

.button-card--disabled-overlay {
    opacity: 0.5;
}

.button-card--disabled-overlay:not(.button-card--disabled):hover {
    opacity: 1;
}

.button-card--transparent {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
}

.button-card--flex {
    flex: 1;
}
